<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12">
        <b-alert
          show
          :variant="localGroupStatus.variant"
        >
          <div class="alert-body">
            <b> {{ localGroupStatus.title }} </b> <br>
            <span>{{ localGroupStatus.body }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
      >
        <statistic-card-vertical
          icon="CloudSnowIcon"
          :style="(co2variant === 'danger') ? 'box-shadow: 0px 0px 10px 7px #F97794' : ''"
          :color="co2variant"
          :statistic="co2value + ' ppm'"
          :statistic-title="$t('co2').toString()"
        />
      </b-col>
      <b-col
        md="6"
      >
        <statistic-card-vertical
          icon="HeadphonesIcon"
          :style="(dbVariant === 'danger') ? 'box-shadow: 0px 0px 10px 7px #F97794' : ''"
          :color="dbVariant"
          :statistic="dbValue + ' dB'"
          :statistic-title="$t('volume').toString()"
        />
      </b-col>
      <b-col
        md="6"
      >
        <statistic-card-vertical
          icon="KeyIcon"
          :style="(!doorValue) ? 'box-shadow: 0px 0px 10px 7px #F97794' : ''"
          :color="doorVariant"
          :statistic="doorText"
          :statistic-title="$t('doorlock').toString()"
        />
      </b-col>
      <b-col
        md="6"
      >
        <statistic-card-vertical
          :style="(!windowValue) ? 'box-shadow: 0px 0px 10px 7px #F97794' : ''"
          icon="WindIcon"
          :color="windowVariant"
          :statistic="windowText"
          :statistic-title="$t('window').toString()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  name: 'LocalGroupDetailDashboard',
  components: {
    StatisticCardVertical,
    BCol,
    BRow,
    BAlert,
  },
  computed: {
    selectedLocalGroup() {
      return this.$store.getters['localGroups/getLocalGroupById'](parseInt(this.localGroupId, 10))
    },
    localGroupId() {
      return this.$route.query.id
    },
    co2value() {
      return Math.floor(Math.random() * 2000)
    },
    co2variant() {
      if (this.co2value < 750) {
        return 'success'
      }
      if (this.co2value < 1500) {
        return 'warning'
      }
      return 'danger'
    },
    dbValue() {
      return Math.floor(Math.random() * 100)
    },
    dbVariant() {
      if (this.dbValue < 35) {
        return 'success'
      }
      if (this.dbValue < 70) {
        return 'warning'
      }
      return 'danger'
    },
    doorValue() {
      return Math.random() < 0.5
    },
    doorText() {
      if (this.doorValue) {
        return this.$t('locked')
      }
      return this.$t('unlocked')
    },
    doorVariant() {
      if (this.doorValue) {
        return 'success'
      }
      return 'danger'
    },
    windowValue() {
      return Math.random() < 0.5
    },
    windowText() {
      if (this.windowValue) {
        return this.$t('closed')
      }
      return this.$t('open')
    },
    windowVariant() {
      if (this.windowValue) {
        return 'success'
      }
      return 'danger'
    },
    localGroupStatus() {
      if (this.dbVariant === 'danger' || this.co2variant === 'danger') {
        return { title: this.$t('warning'), body: this.$t('warningSensors'), variant: 'danger' }
      }
      if (!this.doorValue || !this.windowValue || this.dbVariant === 'warning' || this.co2variant === 'warning') {
        return { title: this.$t('potentialDanger'), body: this.$t('potentialDangerSensors'), variant: 'warning' }
      }
      return { title: this.$t('everythingAlright'), body: this.$t('everythingAlrightSensors'), variant: 'success' }
    },
  },
}
</script>

<style scoped>

</style>
